import { put, takeLatest, all } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadOrganizationProfile,
  loadOrganizationProfileError,
  loadOrganizationProfileSuccess,
  updateOrganizationProfile,
  updateOrganizationProfileError,
} from "./slice";

export function* loadOrganizationProfileSaga({ payload }) {
  try {
    const { id, accessToken } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/Organizations/${id}`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (!response.ok) {
      const errorData = yield response.json();
      throw new Error(
        errorData.message || "Failed to load organization profile"
      );
    }
    const data = yield response.json();
    data.addresses = data.addresses.map((a) => {
      return { ...a, isMainAddress: a.id === data.mainAddressId };
    });
    yield put(loadOrganizationProfileSuccess(data));
  } catch (error) {
    yield put(loadOrganizationProfileError(error.message));
  }
}

export function* updateOrganizationProfileSaga({ payload }) {
  try {
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/Organizations/${payload.id}/`,
      {
        method: "PUT",
        body: JSON.stringify(payload.details),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (!response.ok) {
      const errorData = yield response.json();
      throw new Error(
        errorData.message || "Failed to update organization profile"
      );
    }

    yield put(
      loadOrganizationProfile({
        id: payload.id,
        accessToken: payload.accessToken,
      })
    );
  } catch (error) {
    yield put(updateOrganizationProfileError(error.message));
  }
}

export default function* organizationProfileSagas() {
  yield all([
    yield takeLatest(loadOrganizationProfile.type, loadOrganizationProfileSaga),
    yield takeLatest(
      updateOrganizationProfile.type,
      updateOrganizationProfileSaga
    ),
  ]);
}
