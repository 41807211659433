import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Input from "components/GlobalComponents/Inputs/Input";
import { getErrorText } from "components/FormContainer/utils/utils";

import "./formik-input-field.scss";

export const FormikInputField = ({
  form,
  field,
  label,
  id,
  errors,
  explanation,
  validateField,
  onBlur,
  onChange,
  isTouched,
  mediumHeight,
  isRequired,
  isDark,
  isDisabled,
  isAutoFocus,
  inputValue,
  maxLength,
  readOnly,
  inputType,
  className,
  dataTestId,
}) => {
  const [errorText, setErrorText] = useState(getErrorText(errors, field.name));

  useEffect(() => {
    isTouched && validateField(field.name);
  }, [field.value, field.name, isTouched, validateField]);

  useEffect(() => {
    setErrorText(getErrorText(errors, field.name));
  }, [errors, field.name]);

  return (
    <Input
      id={id}
      name={field.name}
      label={label}
      value={field.value || inputValue}
      explanation={explanation}
      onChange={(obj) => {
        form.setFieldValue(field.name, obj.value);
        onChange(obj);
      }}
      onBlur={(e) => {
        onBlur(e);
        validateField(field.name);
      }}
      className={classNames("formik-input", className)}
      isDark={isDark}
      errorText={errorText}
      mediumHeight={mediumHeight}
      isRequired={isRequired}
      isDisabled={isDisabled}
      readOnly={readOnly}
      isAutoFocus={isAutoFocus}
      maxLength={maxLength}
      inputType={inputType}
      dataTestId={dataTestId}
    />
  );
};

FormikInputField.defaultProps = {
  label: "",
  id: "",
  form: {},
  field: {},
  errors: {},
  explanation: "",
  validateField: () => {},
  onBlur: () => {},
  onChange: () => {},
  isTouched: false,
  mediumHeight: false,
  isRequired: false,
  isDark: false,
  isDisabled: false,
  isAutoFocus: false,
  inputValue: "",
  readOnly: false,
  inputType: "text",
  className: "",
  dataTestId: "",
};

FormikInputField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  errors: PropTypes.object,
  explanation: PropTypes.string,
  validateField: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  isTouched: PropTypes.bool,
  mediumHeight: PropTypes.bool,
  isRequired: PropTypes.bool,
  isDark: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
  inputValue: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  inputType: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};
