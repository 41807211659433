import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import classNames from "classnames";

import SelectDropdownIcon from "./components/SelectDropdownIcon/SelectDropdownIcon";
import SelectInputField from "./components/SelectInputField/SelectInputField";
import InputError from "components/GlobalComponents/Inputs/InputError";
import LabelWithIcon from "../LabeledSelectInput/components/LabelWithIcon";
import styles from "scss/base/_export.module.scss";
import { secondsToMs } from "utils/secondsToMs/secondsToMs";
import { ariaLabelMessageSingleSelect } from "./utils/accessibilityText";
import "./select-input.scss";

export const customSelectStyles = {
  container: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({}),
  indicatorsContainer: () => ({}),
  indicatorSeparator: () => ({}),
  menu: () => ({}),
  menuList: () => ({}),
  option: () => ({}),
  singleValue: () => ({}),
  valueContainer: () => ({}),
  placeholder: () => ({}),
};

const SelectInput = ({
  id,
  className,
  customControl,
  value,
  label,
  isDisabled,
  isReadOnly,
  selectMenuRightAligned,
  onChange,
  onBlur,
  onMenuOpen,
  onMenuClose,
  options,
  isMediumHeight,
  errorText,
  isRequired,
  customStyles,
  isOptionWithLabel,
  customSelectComponents,
  prefixText,
  explanation,
  placeholderText,
  isDark,
  isAutoFocus,
  menuPortalTarget,
  isMenuPlacementTop,
  scrollDropdownIntoView,
  forwardedRef,
  isMultiSelect,
  setMultiValueLabel,
  setMultiValueText,
  setMultiValueIcon,
  dataTestId,
}) => {
  const [selectValue, setSelectValue] = useState(value);
  const { Control, ValueContainer, SingleValue } = components;
  const selectRef = useRef();
  const usedRef = forwardedRef || selectRef;
  const transitionTime = secondsToMs(styles.transitionBase);

  useEffect(() => {
    if (!value) {
      setSelectValue(null);
    } else {
      if (value && value.label) {
        setSelectValue(value);
      } else {
        const valuesArray = Array.isArray(value) ? value : [value];
        const optionValue = options.filter(
          (option) =>
            option.value === value ||
            option.id === value ||
            valuesArray.includes(option.id) ||
            valuesArray.includes(option.value)
        );

        const selectedValue = {
          selectedOptions: optionValue,
          key: id,
        };

        setSelectValue(selectedValue);
      }
    }
  }, [value, options, id]);

  const customComponents = {
    Control: customControl ? customControl : Control,
    DropdownIndicator: SelectDropdownIcon,
    ValueContainer: customControl ? ValueContainer : SelectInputField,
    SingleValue: !customControl
      ? SingleValue
      : () => {
          return null;
        },
  };

  const onSelectChange = (newValue) => {
    let selectedValue;

    if (isMultiSelect) {
      selectedValue = {
        selectedOptions: newValue,
        key: id,
      };
    } else {
      selectedValue = {
        ...newValue,
        key: id,
      };
    }

    setSelectValue(selectedValue);
    onChange(selectedValue);
  };

  const handleOnMenuOpen = () => {
    onMenuOpen();
    if (scrollDropdownIntoView) {
      setTimeout(() => {
        const dropdownRef = usedRef.current?.select?.menuListRef;
        dropdownRef?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }, transitionTime);
    }
  };

  const getFormatOptionLabel = (props) => {
    let isSelected = false;
    if (isMultiSelect && selectValue) {
      const selectedOptionsIds = selectValue.selectedOptions.map(
        (option) => option.id
      );
      isSelected = selectedOptionsIds.includes(props.id);
    }

    return (
      <LabelWithIcon
        {...props}
        isMultiSelect={isMultiSelect}
        isSelected={isSelected}
      />
    );
  };

  const getValue = () => {
    if (isMultiSelect) {
      return selectValue?.selectedOptions;
    }

    return selectValue?.value && selectValue?.label && selectValue;
  };

  return (
    <div
      data-testid="select-input"
      className={classNames("select-input", {
        "select-input--medium-height": isMediumHeight,
        "select-input--dark": isDark,
        "select-input--error": errorText,
        "select-input--disabled": isDisabled,
        "select-input--readonly": isReadOnly,
        "select-input--menu-placement-top": isMenuPlacementTop,
      })}
    >
      <Select
        className={classNames(className, {
          "is-active": selectValue && selectValue.value,
          "is-right-aligned": selectMenuRightAligned,
        })}
        classNamePrefix="select-input"
        aria-label={ariaLabelMessageSingleSelect(
          isRequired,
          errorText,
          label,
          selectValue
        )}
        components={customSelectComponents || customComponents}
        dataTestId={dataTestId}
        data-testid={dataTestId}
        formatOptionLabel={isOptionWithLabel && getFormatOptionLabel}
        prefixText={prefixText}
        placeholderText={placeholderText}
        isDisabled={isDisabled}
        isSearchable={false}
        onChange={onSelectChange}
        onBlur={() => onBlur(selectValue)}
        isOptionDisabled={(option) => option.disabled}
        options={options}
        placeholder={label}
        value={getValue()}
        styles={customStyles || customSelectStyles}
        onMenuOpen={handleOnMenuOpen}
        onMenuClose={onMenuClose}
        isRequired={isRequired}
        autoFocus={isAutoFocus}
        menuPortalTarget={menuPortalTarget}
        menuIsOpen={isReadOnly ? false : undefined}
        ref={usedRef}
        isMulti={isMultiSelect}
        closeMenuOnSelect={!isMultiSelect}
        hideSelectedOptions={false}
        selectMultiValueProps={{
          setMultiValueLabel,
          setMultiValueText,
          setMultiValueIcon,
        }}
        isClearable={false}
      />
      {!errorText && explanation && (
        <span
          data-testid="select-input-explanation"
          className="select-input__explanation"
        >
          {explanation}
        </span>
      )}
      {errorText && <InputError errorText={errorText} />}
    </div>
  );
};

SelectInput.defaultProps = {
  id: "",
  className: "",
  value: "",
  selectMenuRightAligned: false,
  isDisabled: false,
  isReadOnly: false,
  options: [],
  onChange: () => {},
  onBlur: () => {},
  onMenuOpen: () => {},
  onMenuClose: () => {},
  isMediumHeight: false,
  errorText: "",
  isRequired: false,
  customStyles: null,
  isOptionWithLabel: false,
  customSelectComponents: null,
  prefixText: "",
  explanation: "",
  placeholderText: "",
  isDark: false,
  isAutoFocus: false,
  menuPortalTarget: null,
  isMenuPlacementTop: false,
  scrollDropdownIntoView: false,
  forwardedRef: null,
  isMultiSelect: false,
  setMultiValueLabel: () => {},
  setMultiValueText: () => {},
  setMultiValueIcon: () => {},
  dataTestId: "select-input",
};

SelectInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  customControl: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ]),
  selectMenuRightAligned: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onMenuOpen: PropTypes.func,
  onMenuClose: PropTypes.func,
  isMediumHeight: PropTypes.bool,
  errorText: PropTypes.string,
  isRequired: PropTypes.bool,
  customStyles: PropTypes.object,
  isOptionWithLabel: PropTypes.bool,
  customSelectComponents: PropTypes.object,
  prefixText: PropTypes.string,
  explanation: PropTypes.string,
  placeholderText: PropTypes.string,
  isDark: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
  menuPortalTarget: PropTypes.object,
  isMenuPlacementTop: PropTypes.bool,
  scrollDropdownIntoView: PropTypes.bool,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isMultiSelect: PropTypes.bool,
  setMultiValueLabel: PropTypes.func,
  setMultiValueText: PropTypes.func,
  setMultiValueIcon: PropTypes.func,
  dataTestId: PropTypes.string,
};

export default SelectInput;
