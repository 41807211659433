import { createSelector } from "@reduxjs/toolkit";

import slice from "./slice";

export const selectSlice = (state) => state[slice.name];

export const selectData = createSelector([selectSlice], (slice) => slice.data);

export const selectIsLoading = createSelector(
  [selectSlice],
  (slice) => slice.isLoading
);

export const selectError = createSelector(
  [selectSlice],
  (slice) => slice.error
);

export const selectModalContent = createSelector(
  [selectSlice],
  (slice) => slice.modalContent
);

export const selectFormSubmitErrors = createSelector(
  [selectSlice],
  (slice) => slice.modalContent.formSubmitErrors
);

export const selectRolesAvailableForSelect = createSelector(
  [selectSlice],
  (slice) => slice.availableOrganizationRoles
);
