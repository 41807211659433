import { put, takeLatest } from "redux-saga/effects";
import { fetch } from "whatwg-fetch";

import {
  loadUserInfo,
  loadUserInfoError,
  loadUserInfoSuccess,
  setIsPasswordModalOpen,
  updatePassword,
  updatePasswordError,
  updatePasswordSuccess,
} from "./slice";
import { addToast } from "services/toast/slice";

export function* loadUserInfoSaga({ payload }) {
  try {
    const response = yield fetch(
      process.env.REACT_APP_API_URL + "/users/info",
      {
        headers: {
          Authorization: "Bearer " + payload.accessToken,
        },
      }
    );
    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();
    yield put(loadUserInfoSuccess(data));
  } catch ({ message }) {
    yield put(loadUserInfoError(message));
  }
}

export function* updatePasswordSaga({ payload }) {
  try {
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/Users/ChangePassword/`,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + payload.accessToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currentPassword: payload.currentPassword,
          newPassword: payload.newPassword,
        }),
      }
    );
    if (response.status === 200) {
      const data = yield response.json();
      yield put(updatePasswordSuccess(data));
      yield put(setIsPasswordModalOpen(false));
      yield put(addToast({ description: "Password updated successfully" }));
    } else {
      throw new Error("Failed to update password");
    }
  } catch ({ message }) {
    yield put(updatePasswordError(message));
  }
}

export default function* headerSagas() {
  yield takeLatest(loadUserInfo.type, loadUserInfoSaga);
  yield takeLatest(updatePassword.type, updatePasswordSaga);
}
