import { defineMessages } from "react-intl";

const scope = "components.Header.components.ChangePasswordModal.";

export default defineMessages({
  changePasswordModalTitle: {
    id: `${scope}changePasswordModalTitle`,
    defaultMessage: "Change Password",
  },
  passwordLengthError: {
    id: `${scope}passwordLengthError`,
    defaultMessage: "Password must be at least 8 characters long",
  },
  passwordUpperCaseError: {
    id: `${scope}passwordUpperCaseError`,
    defaultMessage: "Password must contain at least one uppercase letter",
  },
  passwordLowerCaseError: {
    id: `${scope}passwordLowerCaseError`,
    defaultMessage: "Password must contain at least one lowercase letter",
  },
  passwordNumberError: {
    id: `${scope}passwordNumberError`,
    defaultMessage: "Password must contain at least one number",
  },
  passwordSpecialCharacterError: {
    id: `${scope}passwordSpecialCharacterError`,
    defaultMessage: "Password must contain at least one special character",
  },
  confirmPassword: {
    id: `${scope}confirmPassword`,
    defaultMessage: "Confirm Your Password",
  },
  currentPasswordRequired: {
    id: `${scope}currentPasswordRequired`,
    defaultMessage: "Current Password is required",
  },
  newPasswordRequired: {
    id: `${scope}newPasswordRequired`,
    defaultMessage: "New password is required",
  },
  passwordsMustMatch: {
    id: `${scope}passwordsMustMatch`,
    defaultMessage: "Passwords must match",
  },
  passwordContainsPersonalInfoError: {
    id: `${scope}passwordContainsPersonalInfoError`,
    defaultMessage: "Passwords must not contain your First Name or Last Name",
  },
  modalInstructions: { id: `${scope}modalInstructions`, defaultMessage: "Use the below form to change your password" },
});
