import { all, put, takeLatest, select } from "redux-saga/effects";
import { createIntl } from "react-intl";

import { selectLocale } from "components/LanguageProvider/services/selectors";
import { addToast } from "services/toast/slice";
import { TOAST_TYPES } from "app/constants";
import messages from "app/messages";
import {
  addOrganizationUserError,
  addOrganizationUserSuccess,
  addUser,
  loadOrganizationPeople,
  loadOrganizationPeopleError,
  loadOrganizationPeopleSuccess,
  loadRoles,
  loadRolesError,
  loadRolesSuccess,
  setFormSubmitErrors,
  setIsFormSubmitSuccess,
  setIsFormSubmitting,
  updateOrganizationUser,
  updateOrganizationUserError,
  updateOrganizationUserSuccess,
  updateWithCanIEdit,
  validateUserEmail,
  validateUserEmailError,
  validateUserEmailSuccess,
} from "./slice";

export function* loadOrganizationPeopleSaga({ payload }) {
  try {
    const { id, accessToken } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${id}/users`,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === 200) {
      const data = yield response.json();
      yield put(loadOrganizationPeopleSuccess(data));
      yield put(updateWithCanIEdit());
    } else {
      yield put(loadOrganizationPeopleError("Failed to load"));
    }
  } catch ({ message }) {
    yield put(loadOrganizationPeopleError(message));
  }
}

export function* addUserSaga({ payload }) {
  try {
    yield put(setIsFormSubmitting(true));

    const { accessToken, organizationId, values } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/users`,
      {
        method: "POST",
        body: JSON.stringify({ ...values }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const data = yield response.json();
      yield put(setIsFormSubmitSuccess(true));
      yield put(setFormSubmitErrors({}));
      yield put(addOrganizationUserSuccess(data));
      yield put(
        addToast({
          description: `${data.firstName} ${data.lastName} has been invited to complete an account`,
        })
      );
    } else {
      const data = yield response.json();
      if (data) {
        yield put(setFormSubmitErrors(data));
      }
      const locale = yield select(selectLocale);
      const intl = createIntl({ locale: locale, messages: messages });
      const errorToast = {
        description: intl.formatMessage(messages.error),
        type: TOAST_TYPES.error,
      };
      yield put(addToast(errorToast));
      yield put(addOrganizationUserError(data));
    }
  } catch ({ message }) {
    yield put(addOrganizationUserError(message));
  }
}

export function* loadRolesSaga({ payload }) {
  try {
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/Roles/available-roles`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${payload.accessToken}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to load");
    }
    const data = yield response.json();

    yield put(loadRolesSuccess(data));
    yield put(updateWithCanIEdit());
  } catch ({ message }) {
    yield put(loadRolesError(message));
  }
}

export function* validateUserEmailSaga({ payload }) {
  try {
    const { accessToken, email, organizationId } = payload;

    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/users/validate`,
      {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      yield put(validateUserEmailSuccess({ email }));
    } else {
      const data = yield response.json();
      yield put(validateUserEmailError({ ...data, email }));
    }
  } catch ({ message }) {
    yield put(validateUserEmailError(message));
  }
}

export function* updateUserSaga({ payload }) {
  try {
    const { accessToken, organizationId, userId, values } = payload;
    const response = yield fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/users/${userId}`,
      {
        method: "PUT",
        body: JSON.stringify({ ...values }),
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      yield put(updateOrganizationUserSuccess(values));
      yield put(loadOrganizationPeople({ id: organizationId, accessToken }));
      yield put(
        addToast({
          description: `${values.firstName} ${values.lastName} has been updated`,
        })
      );
    } else {
      const data = yield response.json();
      if (data) {
        yield put(setFormSubmitErrors(data));
      }
      const locale = yield select(selectLocale);
      const intl = createIntl({ locale: locale, messages: messages });
      const errorToast = {
        description: intl.formatMessage(messages.error),
        type: TOAST_TYPES.error,
      };
      yield put(addToast(errorToast));
      yield put(updateOrganizationUserError(data));
    }
  } catch ({ message }) {
    yield put(updateOrganizationUserError(message));
  }
}

export default function* organizationPeopleSaga() {
  yield all([
    yield takeLatest(loadOrganizationPeople.type, loadOrganizationPeopleSaga),
    yield takeLatest(loadRoles.type, loadRolesSaga),
    yield takeLatest(validateUserEmail.type, validateUserEmailSaga),
    yield takeLatest(addUser.type, addUserSaga),
    yield takeLatest(updateOrganizationUser.type, updateUserSaga),
  ]);
}
