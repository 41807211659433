import { combineReducers } from "@reduxjs/toolkit";

import myNetworkPageSlice from "pages/MyNetworkPage/services/slice";
import assessmentsSlice from "pages/AssessmentsPage/services/slice";
import assessmentDetailsSlice from "components/AssessmentDetails/services/slice";
import purchaseAssessmentSlice from "components/BulkAssessmentActions/components/PurchaseAssessment/services/slice";
import authorizationSlice from "components/AuthorizedComponent/slice";
import catalogFiltersSlice from "pages/CatalogPage/services/slice";
import localeSlice from "components/LanguageProvider/services/slice";
import thirdPartyUploadSlice from "components/UploadThirdPartiesModal/services/slice";
import myThirdPartiesSlice from "pages/MyListPage/MyThirdPartiesPage/services/slice";
import purchasedSlice from "pages/PurchasedPage/services/slice";
import unmatchedThirdPartiesSlice from "pages/MyListPage/UnmatchedPage/services/slice";
import userSlice from "components/Header/services/slice";
import authorization from "components/AuthorizedRoute/services/slice";
import notificationsSlice from "components/Notifications/services/slice";
import bulkAssessmentActionsSlice from "components/BulkAssessmentActions/services/slice";
import thirdPartyDetailsSlice from "pages/MyListPage/MyThirdPartiesPage/components/ThirdPartyDetails/services/slice";
import globalServiceSlice from "services/toast/slice";
import breadcrumbsSlice from "services/breadcrumbs/slice";
import thirdPartiesSlice from "pages/ThirdPartiesPage/services/slice";
import truSightUsersSlice from "pages/TruSightUsersPage/services/slice";
import thirdPartyAssessmentsSlice from "pages/ThirdPartyAssessmentsPage/services/slice";
import thirdPartySlice from "pages/ThirdPartyPage/services/slice";
import companyProfileSlice from "pages/ThirdPartyPage/CompanyProfile/services/slice";
import assessmentSlice from "pages/AssessmentPage/services/slice";
import classifierItemsSlice from "services/classifiers/slice";
import drawerFieldsSlice from "services/drawer/slice";
import documentSlice from "services/documents/slice";
import formInfoSlice from "services/form/slice";
import scopingQuestionnaireSlice from "pages/AssessmentPage/Scoping/services/slice";
import artifactsSlice from "pages/AssessmentPage/Artifacts/services/slice";
import bpqQuestionnaireSlice from "pages/AssessmentPage/BPQ/services/slice";
import expandableContainerSlice from "components/ExpandableContainer/services/slice";
import requestsPageSlice from "pages/MyListPage/RequestsPage/services/slice";
import qcFlagDrawerSlice from "pages/AssessmentPage/BPQ/components/QCFlagDrawer/services/slice";
import thirdPartyResponseDrawerSlice from "pages/AssessmentPage/BPQ/components/ThirdPartyResponseDrawer/services/slice";
import thematicFlagsSlice from "pages/AssessmentPage/BPQ/components/ThematicFlagsDrawer/services/slice";
import bpqScopingSlice from "pages/AssessmentPage/BpqScoping/services/slice";
import authorizationsSlice from "pages/AssessmentPage/Authorizations/services/slice";
import productDownloadSlice from "pages/AssessmentPage/ProductDownload/services/slice";
import overviewSlice from "pages/AssessmentPage/Overview/services/slice";
import apiKeysSlice from "pages/SettingsPage/components/ApiKeysSection/services/slice";
import cusSlice from "pages/TemplatesAndMappingPage/CustomTemplatePage/services/slice";
import templatesAndMappingSlice from "pages/TemplatesAndMappingPage/services/slice";
import tagCollectionSlice from "pages/TemplatesAndMappingPage/TagCollectionPage/services/slice";
import artifactAssociationSlice from "pages/AssessmentPage/BPQ/components/ArtifactAssociation/services/slice";
import submitBpqModalSlice from "pages/AssessmentPage/BPQ/components/SubmitBpqModal/services/slice";
import rightSideControlsSlice from "pages/AssessmentPage/components/RightSideControls/services/slice";
import bpqCommentBuilderSlice from "pages/AssessmentPage/BPQ/components/BPQCommentBuilder/services/slice";
import assessmentReviewCommentDrawerSlice from "pages/AssessmentPage/BPQ/components/AssessmentReviewCommentDrawer/services/slice";
import peerReviewDrawerSlice from "pages/AssessmentPage/BPQ/components/PeerReviewDrawer/services/slice";
import companyPeopleSlice from "pages/ThirdPartyPage/People/services/slice";
import assessmentPeopleListSlice from "pages/AssessmentPage/People/services/slice";
import designElementsPanelSlice from "pages/AssessmentPage/BPQ/components/BpqQuestionnaire/services/slice";
import apiDocumentationSlice from "pages/DeveloperGuidePage/services/slice";
import tagsAndCollectionsSlice from "pages/TemplatesAndMappingPage/TagsAndCollections/services/slice";
import customTemplatesSlice from "pages/TemplatesAndMappingPage/CustomTemplates/services/slice";
import purchaseAssessmentWithTemplatesSlice from "components/BulkAssessmentActions/components/PurchaseAssessmentWithTemplates/services/slice";
import manageCustomersSlice from "pages/ManageCustomersPage/services/slice";
import manageCustomerSlice from "pages/ManageCustomerPage/services/slice";
import manageCustomerUnmatchedSlice from "pages/ManageCustomerPage/UnmatchedAdminPage/services/slice";
import unmatchedThirdPartiesHeadingSlice from "pages/ManageCustomerPage/UnmatchedAdminPage/components/UnmatchedThirdPartiesHeading/services/slice";
import bowSlice from "pages/GenerateBow/services/slice";
import typeAheadSlice from "components/GlobalComponents/Inputs/TypeAheadInput/services/slice";
import unmatchedPageAdministrativeButtonsSlice from "components/UnmatchedThirdParties/services/slice";
import unmatchedThirdPartiesTableSlice from "components/UnmatchedThirdParties/components/UnmatchedThirdPartiesTable/services/slice";
import scopingTableRowSlice from "pages/AssessmentPage/Scoping/components/ScopingTable/components/ScopingTableRow/services/slice";
import toggleColumnsVisibilityModalSlice from "components/TableComponents/ToggleColumnsVisibilityModal/services/slice";
import matchingHistoryPageSlice from "pages/ManageCustomerPage/MatchingHistoryPage/services/slice";
import connectionDetailsSlice from "pages/MyNetworkPage/components/ConnectionDetailsDrawer/services/slice";
import myNetworkAddRelationshipModalSlice from "pages/MyNetworkPage/components/AddRelationshipModal/services/slice";
import totalSessionTimerSlice from "components/TotalSessionTimer/services/slice";
import myOrganizationSlice from "pages/OrganizationDetailsPage/services/slice";
import organizationPeopleSlice from "pages/OrganizationPeoplePage/services/slice";
import uploadMappingSlice from "pages/UploadMappingFile/services/slice";

const reduxStates = {
  [assessmentsSlice.name]: assessmentsSlice.reducer,
  [assessmentDetailsSlice.name]: assessmentDetailsSlice.reducer,
  [purchaseAssessmentSlice.name]: purchaseAssessmentSlice.reducer,
  [authorizationSlice.name]: authorizationSlice.reducer,
  [catalogFiltersSlice.name]: catalogFiltersSlice.reducer,
  [localeSlice.name]: localeSlice.reducer,
  [thirdPartyUploadSlice.name]: thirdPartyUploadSlice.reducer,
  [myThirdPartiesSlice.name]: myThirdPartiesSlice.reducer,
  [purchasedSlice.name]: purchasedSlice.reducer,
  [unmatchedThirdPartiesSlice.name]: unmatchedThirdPartiesSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [authorization.name]: authorization.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [bulkAssessmentActionsSlice.name]: bulkAssessmentActionsSlice.reducer,
  [thirdPartyDetailsSlice.name]: thirdPartyDetailsSlice.reducer,
  [globalServiceSlice.name]: globalServiceSlice.reducer,
  [thirdPartiesSlice.name]: thirdPartiesSlice.reducer,
  [truSightUsersSlice.name]: truSightUsersSlice.reducer,
  [thirdPartyAssessmentsSlice.name]: thirdPartyAssessmentsSlice.reducer,
  [thirdPartySlice.name]: thirdPartySlice.reducer,
  [companyProfileSlice.name]: companyProfileSlice.reducer,
  [assessmentSlice.name]: assessmentSlice.reducer,
  [classifierItemsSlice.name]: classifierItemsSlice.reducer,
  [breadcrumbsSlice.name]: breadcrumbsSlice.reducer,
  [drawerFieldsSlice.name]: drawerFieldsSlice.reducer,
  [documentSlice.name]: documentSlice.reducer,
  [formInfoSlice.name]: formInfoSlice.reducer,
  [scopingQuestionnaireSlice.name]: scopingQuestionnaireSlice.reducer,
  [artifactsSlice.name]: artifactsSlice.reducer,
  [bpqQuestionnaireSlice.name]: bpqQuestionnaireSlice.reducer,
  [expandableContainerSlice.name]: expandableContainerSlice.reducer,
  [requestsPageSlice.name]: requestsPageSlice.reducer,
  [qcFlagDrawerSlice.name]: qcFlagDrawerSlice.reducer,
  [thirdPartyResponseDrawerSlice.name]: thirdPartyResponseDrawerSlice.reducer,
  [thematicFlagsSlice.name]: thematicFlagsSlice.reducer,
  [bpqScopingSlice.name]: bpqScopingSlice.reducer,
  [authorizationsSlice.name]: authorizationsSlice.reducer,
  [productDownloadSlice.name]: productDownloadSlice.reducer,
  [overviewSlice.name]: overviewSlice.reducer,
  [apiKeysSlice.name]: apiKeysSlice.reducer,
  [cusSlice.name]: cusSlice.reducer,
  [templatesAndMappingSlice.name]: templatesAndMappingSlice.reducer,
  [tagCollectionSlice.name]: tagCollectionSlice.reducer,
  [artifactAssociationSlice.name]: artifactAssociationSlice.reducer,
  [submitBpqModalSlice.name]: submitBpqModalSlice.reducer,
  [rightSideControlsSlice.name]: rightSideControlsSlice.reducer,
  [bpqCommentBuilderSlice.name]: bpqCommentBuilderSlice.reducer,
  [assessmentReviewCommentDrawerSlice.name]:
    assessmentReviewCommentDrawerSlice.reducer,
  [peerReviewDrawerSlice.name]: peerReviewDrawerSlice.reducer,
  [companyPeopleSlice.name]: companyPeopleSlice.reducer,
  [assessmentPeopleListSlice.name]: assessmentPeopleListSlice.reducer,
  [designElementsPanelSlice.name]: designElementsPanelSlice.reducer,
  [apiDocumentationSlice.name]: apiDocumentationSlice.reducer,
  [tagsAndCollectionsSlice.name]: tagsAndCollectionsSlice.reducer,
  [customTemplatesSlice.name]: customTemplatesSlice.reducer,
  [purchaseAssessmentWithTemplatesSlice.name]:
    purchaseAssessmentWithTemplatesSlice.reducer,
  [manageCustomersSlice.name]: manageCustomersSlice.reducer,
  [manageCustomerSlice.name]: manageCustomerSlice.reducer,
  [manageCustomerUnmatchedSlice.name]: manageCustomerUnmatchedSlice.reducer,
  [unmatchedThirdPartiesHeadingSlice.name]:
    unmatchedThirdPartiesHeadingSlice.reducer,
  [bowSlice.name]: bowSlice.reducer,
  [typeAheadSlice.name]: typeAheadSlice.reducer,
  [unmatchedPageAdministrativeButtonsSlice.name]:
    unmatchedPageAdministrativeButtonsSlice.reducer,
  [unmatchedThirdPartiesTableSlice.name]:
    unmatchedThirdPartiesTableSlice.reducer,
  [scopingTableRowSlice.name]: scopingTableRowSlice.reducer,
  [toggleColumnsVisibilityModalSlice.name]:
    toggleColumnsVisibilityModalSlice.reducer,
  [myNetworkPageSlice.name]: myNetworkPageSlice.reducer,
  [matchingHistoryPageSlice.name]: matchingHistoryPageSlice.reducer,
  [connectionDetailsSlice.name]: connectionDetailsSlice.reducer,
  [myNetworkAddRelationshipModalSlice.name]:
    myNetworkAddRelationshipModalSlice.reducer,
  [totalSessionTimerSlice.name]: totalSessionTimerSlice.reducer,
  [myOrganizationSlice.name]: myOrganizationSlice.reducer,
  [organizationPeopleSlice.name]: organizationPeopleSlice.reducer,
  [uploadMappingSlice.name]: uploadMappingSlice.reducer,
};

const alphabeticallyOrderedReduxStates = Object.keys(reduxStates)
  .sort()
  .reduce((accumulator, key) => {
    accumulator[key] = reduxStates[key];
    return accumulator;
  }, {});

const reducer = combineReducers(alphabeticallyOrderedReduxStates);

export default reducer;
