import { get } from "lodash";

export const getErrorText = (errors, fieldName) => {
  if (errors && Object.values(errors).length > 0) {
    return get(errors, fieldName, "");
  } else {
    return "";
  }
};

export const getMappedOptions = (options) => {
  const mappedOptions = [];
  options &&
    options.length > 0 &&
    options.forEach((option) => {
      if (option.options) {
        option.options.forEach((groupOptions, index) => {
          if (option.options.length - index === 1) {
            mappedOptions.push({
              ...groupOptions,
              isLastInGroup: true,
            });
          } else {
            mappedOptions.push(groupOptions);
          }
        });
      } else {
        mappedOptions.push(option);
      }
    });
  return mappedOptions;
};
