import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import classNames from "classnames";

import Button from "components/GlobalComponents/Buttons/Button";
import Spinner from "components/Spinner";
import { selectIsDirty } from "services/form/selectors";
import { handleDirtyFormPrompt } from "utils/handleDirtyFormPrompt/handleDirtyFormPrompt";
import "./form-controls.scss";

const FormControls = ({
  onCancelClick,
  onConfirmClick,
  confirmBtnText,
  cancelBtnText,
  isFormSavePending,
  isConfirmButtonDisabled,
  showCancelButton,
  isCancelButtonLight,
  areButtonsLarge,
  alignButtonsRight,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isDirty = useSelector(selectIsDirty);

  const handleCancelClick = (e) => {
    handleDirtyFormPrompt(() => onCancelClick(e), isDirty, intl, dispatch);
  };

  return (
    <div
      className={classNames("form-controls", {
        "form-controls--buttons-aligned-right": alignButtonsRight,
      })}
      data-testid="form-controls"
    >
      {showCancelButton && (
        <Button
          data-testid="form-controls-cancel-button"
          className="form-controls__button"
          isLight={isCancelButtonLight}
          onClick={handleCancelClick}
          isLarge={areButtonsLarge}
          isSecondary
        >
          {cancelBtnText}
        </Button>
      )}
      <Button
        data-testid="form-controls-confirm-button"
        className="form-controls__button"
        onClick={onConfirmClick}
        isDisabled={isConfirmButtonDisabled}
        isLarge={areButtonsLarge}
        isHighlighted
      >
        {isFormSavePending ? (
          <>
            <Spinner
              className="form-controls__spinner"
              isLight
              data-testid="form-controls-spinner"
            />
            <span>Processing</span>
          </>
        ) : (
          confirmBtnText
        )}
      </Button>
    </div>
  );
};

FormControls.defaultProps = {
  onCancelClick: () => {},
  onConfirmClick: () => {},
  isFormSavePending: false,
  isConfirmButtonDisabled: false,
  showCancelButton: false,
  isCancelButtonLight: false,
  areButtonsLarge: false,
  alignButtonsRight: false,
};

FormControls.propTypes = {
  onCancelClick: PropTypes.func,
  onConfirmClick: PropTypes.func,
  confirmBtnText: PropTypes.string.isRequired,
  cancelBtnText: PropTypes.string.isRequired,
  isFormSavePending: PropTypes.bool,
  isConfirmButtonDisabled: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  isCancelButtonLight: PropTypes.bool,
  areButtonsLarge: PropTypes.bool,
  alignButtonsRight: PropTypes.bool,
};

export default FormControls;
