import React, { useEffect, useRef, useState } from "react";

import Button from "components/GlobalComponents/Buttons/Button";
import DropdownMenu from "components/DropdownMenu";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import { ReactComponent as ChevronIcon } from "assets/chevron.svg";
import { ReactComponent as DocumentDownloadIcon } from "assets/document-download.svg";
import UseCheckOutsideClick from "utils/useCheckOutsideClick/useCheckOutsideClick";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import { downloadFile } from "utils/handleDownload/handleDownload";

import "./catalog-page-right-side.scss";

export const updateDownloadFileName = (response) => {
  const firstPartOfFileName = response.headers
    .get("content-disposition")
    .split('filename="');

  // Check if file name has quotes and ignore them
  if (firstPartOfFileName.length > 1) {
    return decodeURI(firstPartOfFileName[1].split('";')[0]);
  } else {
    return decodeURI(
      response.headers
        .get("content-disposition")
        .split("filename=")[1]
        .split(";")[0]
    );
  }
};

const CatalogPageRightSide = () => {
  const [downloadList, setDownloadList] = useState([]);
  const downloadDropdownWrapper = useRef();
  const accessToken = useAccessToken();
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);

  const toggleDownloadDropdown = () => {
    setIsDownloadDropdownOpen(!isDownloadDropdownOpen);
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `/regulatorymapping`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((json) => {
          setDownloadList(json);
        });
      }
    });
  }, [accessToken]);

  UseCheckOutsideClick(
    downloadDropdownWrapper,
    toggleDownloadDropdown,
    isDownloadDropdownOpen
  );

  const downloadMappingFile = (BPQVersion) => async () => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + `/regulatorymapping/${BPQVersion}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.status === 200) {
      downloadFile(response, updateDownloadFileName(response));
      toggleDownloadDropdown();
    }
  };

  return (
    <div
      className="catalog-section__download-wrapper"
      ref={downloadDropdownWrapper}
    >
      <Button
        onClick={toggleDownloadDropdown}
        leadingIcon={<DownloadIcon />}
        followingIcon={<ChevronIcon />}
        isPrimary
        isSmall
        dataTestIdPrefix="download-dropdown"
      >
        Download
      </Button>
      <DropdownMenu isDropdownOpen={isDownloadDropdownOpen}>
        {downloadList.map((item) => (
          <button
            className="dropdown-menu__item"
            onClick={downloadMappingFile(item.bpqVersion)}
            type="button"
            data-testid="download-all-unredacted-button"
            key={item.bpqVersion}
          >
            <span className="dropdown-menu__item-contents">
              <DocumentDownloadIcon />
              <span>{item.bpqVersion}</span>
            </span>
          </button>
        ))}
      </DropdownMenu>
    </div>
  );
};

export default CatalogPageRightSide;
