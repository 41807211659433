import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "formik";

import { selectIsDirty } from "services/form/selectors";
import { setIsDirty } from "services/form/slice";
import "./form-content.scss";

export const FormContent = ({
  children,
  formikProps,
  setFormikProps,
  customIsDirtyComparator,
  hasOuterForm,
}) => {
  const [updatedFormikProps, setUpdatedFormikProps] = useState(null);
  const dispatch = useDispatch();
  const isDirty = useSelector(selectIsDirty);

  useEffect(() => {
    return () => {
      !hasOuterForm && dispatch(setIsDirty({ value: false }));
    };
  }, [dispatch, hasOuterForm]);

  useEffect(() => {
    if (JSON.stringify(formikProps) !== JSON.stringify(updatedFormikProps)) {
      setUpdatedFormikProps(formikProps);
      setFormikProps(formikProps);
    }
  }, [formikProps, setFormikProps, updatedFormikProps]);

  useEffect(() => {
    if (!isDirty && formikProps.dirty && !hasOuterForm) {
      if (customIsDirtyComparator) {
        customIsDirtyComparator() && dispatch(setIsDirty({ value: true }));
      } else {
        dispatch(setIsDirty({ value: true }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, formikProps.dirty, customIsDirtyComparator, hasOuterForm]);
  return (
    <Form data-testid="form-content" className="form-content">
      {children}
    </Form>
  );
};

FormContent.defaultProps = {
  hasOuterForm: false,
  hideLeavePrompt: false,
};

FormContent.propTypes = {
  children: PropTypes.node,
  formikProps: PropTypes.object,
  isFormControlsVisible: PropTypes.bool,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  onCancel: PropTypes.func,
  setFormikProps: PropTypes.func,
  customIsDirtyComparator: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  hasOuterForm: PropTypes.bool,
};
