import { createSlice } from "@reduxjs/toolkit";

const NAMESPACE = "UploadMapping";

export const initialState = {
  mappingFileUploading: false,
  errors: {},
  loadSuccess: false,
  loading: false,
  loadError: null,
};

const slice = createSlice({
  name: NAMESPACE,
  initialState,
  reducers: {
    startUploadFiles: (state) => {
      state.mappingFileUploading = true;
    },
    stopUploadFiles: (state) => {
      state.mappingFileUploading = false;
    },
    uploadMappingFileSuccess: (state) => {
      state.mappingFileUploading = false;
      state.loadSuccess = true;
      state.errors = {};
    },
    uploadFileError: (state, action) => {
      state.mappingFileUploading = false;
      state.errors = action.payload.errors;
    },
    resetInfo: (state) => {
      state.loadSuccess = false;
      state.mappingFileUploading = false;
      state.errors = {};
    },
  },
});

export const {
  startUploadFiles,
  stopUploadFiles,
  uploadMappingFileSuccess,
  uploadFileError,
  resetInfo,
} = slice.actions;

export default slice;
