import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import * as Yup from "yup";

import FormContainer from "components/FormContainer/FormContainer";
import TsModal from "components/GlobalComponents/Modal";
import messages from "./messages";
import { PasswordForm } from "../PasswordForm/PasswordForm";
import { updatePassword } from "components/Header/services/slice";
import { selectUserName } from "components/Header/services/selectors";
import getSessionAccessToken from "utils/useAccessToken/getSessionAccessToken";

const ChangePasswordModal = ({ setModalOpen }) => {
  const intl = useIntl();
  const [formikProps, setFormikProps] = useState({});
  const dispatch = useDispatch();

  const fullName = useSelector(selectUserName);
  const namesArray = fullName.split(" ");

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const handleSetFormikProps = (formProps) => {
    if (JSON.stringify(formikProps) !== JSON.stringify(formProps)) {
      setFormikProps(formProps);
    }
  };

  const getIsConfirmButtonDisabled = () => {
    const { values, errors, touched, isValid, dirty } = formikProps;

    if (values && errors && touched) {
      const hasEmptyFields =
        !values.currentPassword ||
        !values.newPassword ||
        !values.confirmNewPassword;

      return !isValid || !dirty || hasEmptyFields;
    }

    return true;
  };
  const onSubmit = (values) => {
    dispatch(
      updatePassword({
        accessToken: getSessionAccessToken(),
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
    );
  };

  const ChangePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      intl.formatMessage(messages.currentPasswordRequired)
    ),
    newPassword: Yup.string()
      .required(intl.formatMessage(messages.newPasswordRequired))
      .min(8, intl.formatMessage(messages.passwordLengthError))
      .matches(/[A-Z]/, intl.formatMessage(messages.passwordUpperCaseError))
      .matches(/[a-z]/, intl.formatMessage(messages.passwordLowerCaseError))
      .matches(/[0-9]/, intl.formatMessage(messages.passwordNumberError))
      .matches(
        /[@$!%*?&#]/,
        intl.formatMessage(messages.passwordSpecialCharacterError)
      )
      .test(
        "not-in-name",
        intl.formatMessage(messages.passwordContainsPersonalInfoError),
        function (value) {
          return !namesArray.some((name) =>
            value.toLowerCase().includes(name.toLowerCase())
          );
        }
      ),
    confirmNewPassword: Yup.string()
      .required(intl.formatMessage(messages.confirmPassword))
      .oneOf(
        [Yup.ref("newPassword"), null],
        intl.formatMessage(messages.passwordsMustMatch)
      ),
  });

  return (
    <FormContainer
      initialValues={initialValues}
      onSubmit={onSubmit}
      setFormikProps={handleSetFormikProps}
      validationSchema={ChangePasswordSchema}
    >
      <TsModal
        modalTitle={intl.formatMessage(messages.changePasswordModalTitle)}
        modalHeaderContent={
          <FormattedMessage {...messages.modalInstructions} />
        }
        modalContent={
          <PasswordForm
            onFieldBlur={formikProps.handleBlur}
            validateField={formikProps.validateField}
            errors={formikProps.errors}
            touchedFields={formikProps.touched}
            setTouched={formikProps.setTouched}
            fullName={fullName}
          />
        }
        showFooter
        confirmActionText={"Change Password"}
        isModalOpen
        setModalOpen={setModalOpen}
        onConfirmAction={formikProps.handleSubmit}
        isConfirmButtonDisabled={getIsConfirmButtonDisabled()}
        type="submit"
      />
    </FormContainer>
  );
};

ChangePasswordModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
