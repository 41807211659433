import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { selectIsDirty } from "services/form/selectors";
import { FormContent } from "./components/FormContent/FormContent";
import FormControls from "./components/FormControls";
import "./form-container.scss";

const FormContainer = ({
  className,
  children,
  initialValues,
  onSubmit,
  onCancel,
  confirmBtnText,
  cancelBtnText,
  validationSchema,
  setFormikProps,
  isFormControlsVisible,
  isConfirmButtonDisabled,
  isFormSavePending,
  validateOnBlur,
  validateOnChange,
  hideLeavePrompt,
  showCancelButton,
  enableReinitialize,
  customIsDirtyComparator,
  hasOuterForm,
}) => {
  const isDirty = useSelector(selectIsDirty);

  useEffect(() => {
    if (!hideLeavePrompt) {
      window.onbeforeunload = () => isDirty || null;

      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [isDirty, hideLeavePrompt]);

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues || {}}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
    >
      {(formikProps) => (
        <div className={classNames("form-container", className)}>
          <FormContent
            formikProps={formikProps}
            setFormikProps={setFormikProps}
            customIsDirtyComparator={customIsDirtyComparator}
            hasOuterForm={hasOuterForm}
          >
            {children}
            {isFormControlsVisible && (
              <FormControls
                isConfirmButtonDisabled={
                  Object.values(formikProps.errors).length > 0 ||
                  isConfirmButtonDisabled
                }
                isFormSavePending={isFormSavePending}
                confirmBtnText={confirmBtnText || "Save Changes"}
                cancelBtnText={cancelBtnText || "Cancel Changes"}
                showCancelButton={showCancelButton}
                onConfirmClick={() => onSubmit(formikProps.values)}
                onCancelClick={onCancel}
              />
            )}
          </FormContent>
        </div>
      )}
    </Formik>
  );
};

FormContainer.defaultProps = {
  className: "",
  initialValues: {},
  onSubmit: () => {},
  onCancel: () => {},
  confirmBtnText: "",
  cancelBtnText: "",
  hideLeavePrompt: false,
  validationSchema: null,
  validateOnBlur: true,
  validateOnChange: true,
  setFormikProps: () => {},
  isFormControlsVisible: false,
  isFormSavePending: false,
  showCancelButton: true,
  isConfirmButtonDisabled: false,
  enableReinitialize: false,
  customIsDirtyComparator: false,
  hasOuterForm: false,
};

FormContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  hideLeavePrompt: PropTypes.bool,
  validationSchema: PropTypes.object,
  validateOnBlur: PropTypes.bool,
  validateOnChange: PropTypes.bool,
  setFormikProps: PropTypes.func,
  isFormControlsVisible: PropTypes.bool,
  isFormSavePending: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  isConfirmButtonDisabled: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
  customIsDirtyComparator: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  hasOuterForm: PropTypes.bool,
};

export default FormContainer;
