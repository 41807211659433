import React from "react";
import { Field, useFormikContext } from "formik";
import PropTypes from "prop-types";

import { FormikInputField } from "components/FormContainer/components/FormikInputField/FormikInputField";
import "./password-form.scss";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as Error } from "../../../../assets/error-input.svg";

export const PasswordForm = ({
  errors,
  validateField,
  onFieldBlur,
  fullName,
}) => {
  const { values } = useFormikContext();
  const namesArray = fullName.split(" ");
  const validationRules = [
    {
      message: "Password must be at least 8 characters long.",
      isFulfilled: values.newPassword.length >= 8,
    },
    {
      message: "Password must contain at least one uppercase letter.",
      isFulfilled: /[A-Z]/.test(values.newPassword),
    },
    {
      message: "Password must contain at least one lowercase letter.",
      isFulfilled: /[a-z]/.test(values.newPassword),
    },
    {
      message: "Password must contain at least one number.",
      isFulfilled: /[0-9]/.test(values.newPassword),
    },
    {
      message: "Password must contain at least one special character.",
      isFulfilled: /[@$!%*?&#]/.test(values.newPassword),
    },
    {
      message: "Password cannot contain your first or last name.",
      isFulfilled: !namesArray.some((name) =>
        values.newPassword.toLowerCase().includes(name.toLowerCase())
      ),
    },
    // Add more rules as necessary
  ];
  return (
    <>
      <div className="password-form">
        <div className="password-form__inputs-row">
          <Field
            name="currentPassword"
            component={FormikInputField}
            label="Current Password"
            id="current-password-input"
            dataTestId="current-password-input"
            errors={errors}
            validateField={validateField}
            onBlur={onFieldBlur}
            isRequired
            inputType="password"
          />
        </div>
        <div className="password-form__inputs-row">
          <Field
            name="newPassword"
            component={FormikInputField}
            label="New Password"
            id="new-pasword-input"
            dataTestId="new-password-input"
            //errors={errors}
            validateField={validateField}
            onBlur={onFieldBlur}
            isRequired
            inputType="password"
          />
        </div>
        <div className="password-form__inputs-row">
          <Field
            name="confirmNewPassword"
            component={FormikInputField}
            label="Confirm New Password"
            id="confirm-new-password-input"
            dataTestId="confirm-new-password-input"
            errors={errors}
            validateField={validateField}
            onBlur={onFieldBlur}
            isRequired
            inputType="password"
          />
        </div>
        {validationRules.map((rule, index) => (
          <div key={index} className="password-form__validation-rule">
            {rule.isFulfilled ? (
              <span className="password-form__fulfilled">
                <Check className="password-form__icon" fill="#78f1bc" />
                <span className="password-form__fulfilled-text">
                  {rule.message}
                </span>
              </span>
            ) : (
              <span className="password-form__not-fulfilled">
                <Error className="password-form__icon" fill="#FF2864" />
                <span className="password-form__error-text">
                  {rule.message}
                </span>
              </span>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

PasswordForm.defaultProps = {
  rolesForSelect: [],
  contactsForSelect: [],
  errors: {},
  validateField: () => {},
  onFieldBlur: () => {},
  touchedFields: {},
  setTouched: () => {},
  isDisabled: false,
  emailValue: "",
  wizardStep: null,
  hidePermissionsSelect: false,
  isLoading: false,
  fullName: "",
};

PasswordForm.propTypes = {
  rolesForSelect: PropTypes.array,
  contactsForSelect: PropTypes.array,
  errors: PropTypes.object,
  validateField: PropTypes.func,
  onFieldBlur: PropTypes.func,
  touchedFields: PropTypes.object,
  setTouched: PropTypes.func,
  isDisabled: PropTypes.bool,
  wizardStep: PropTypes.number,
  emailValue: PropTypes.string,
  hidePermissionsSelect: PropTypes.bool,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  fullName: PropTypes.string,
};
