export const HEADER_NAMES = {
  assessmentId: "Assessment ID",
  actions: "Actions",
  additionalComments: "Additional Comments",
  address: "Address",
  answer: "Answer",
  assessmentReview: "Assessment Review",
  assessments: "Assessments",
  attachedArtifacts: "Attached Artifacts",
  author: "Author",
  authorizationStatus: "Authorization Status",
  authorizedBy: "Authorized by",
  authorizedOn: "Authorized on",
  availableToReleaseDate: "Available to Release Date",
  bpqDueDate: "BPQ Due Date",
  bpqVersion: "BPQ Version",
  completedDate: "Completed Date",
  contact: "Contact",
  controlEnvironment: "Control Environment",
  controlEnvironmentDescription: "Control Environment Description",
  createdOn: "Created On",
  customerCompany: "Customer Company",
  customerName: "Customer Name",
  description: "Description",
  domainQuestion: "Domain/Question",
  download: "Download",
  earliestRequest: "Earliest Request",
  email: "Email",
  fieldwork: "Fieldwork",
  fieldworkEndDate: "Fieldwork End Date",
  fieldworkDate: "Fieldwork Date",
  internalNotes: "Internal Notes",
  lastLoggedIn: "Last Logged In",
  lastModified: "Last Modified",
  lastUpdated: "Last Updated",
  lifeCycle: "Life Cycle",
  location: "Location",
  manageArtifact: "Manage Artifact",
  name: "Name",
  notificationsEnabled: "Notifications Enabled",
  observation: "Observation",
  observationDueDate: "Observation Due Date",
  onsite: "Onsite",
  orderDate: "Order Date",
  orderId: "Order ID",
  orderedBy: "Ordered By",
  organization: "Organization",
  overlap: "Overlap",
  owner: "Owner",
  peerReview: "Peer Review",
  qcFlag: "QC Flag",
  qualityControl: "Quality Control",
  question: "Question ID / Question",
  redaction: "Redaction",
  scan: "Scan",
  release: "Release",
  releaseDate: "Release Date",
  requestedOn: "Requested on",
  riskLead: "Risk Lead",
  scope: "Scope",
  serviceCategory: "Service Category",
  serviceCategoryL1: "Service Category L1",
  serviceCategoryL2: "Service Category L2",
  sharedNotes: "Shared Notes",
  stageAdded: "Stage Added",
  status: "Status",
  tagSetName: "Tag Collection Name",
  templateName: "Template Name",
  thirdParty: "Third Party",
  thirdPartyAdmin: "Third Party Admin",
  thirdPartyResponse: "Third Party Response",
  thirdPartySuccess: "Third Party Success",
  title: "Title",
  towerLead: "Tower Lead",
  truSightId: "TruSight ID",
  type: "Type",
  url: "URL",
  version: "Version",
  year: "Year",
  id: "ID",
  questionDesignElement: "Question / Design Element",
  included: "Included",
  permissions: "Permissions",
  productId: "Product ID",
  tags: "Tags",
  purchases: "Purchases",
  price: "Price",
  requester: "Requester",
  requestingCompany: "Requesting Company",
  requesterEmail: "Requester's e-mail",
  thirdParties: "Third Parties",
  matched: "Matched (3rd Party / Candidate)",
  unmatched: "Unmatched",
  pendingMatches: "Pending Matches",
  customerOverlap: "Customer Overlap",
  planned: "Planned",
  inProgress: "In Progress",
  completed: "Completed",
  change: "Change",
  bow: "BoW",
  depth: "Depth",
  organizationName: "Company Name",
  connectionType: "Relation to",
  dunsId: "DUNS ID",
  isCancelled: "Cancelled",
  //Column to decide if user isDisabled or Active
  isDisabled: "Active/Inactive",
};

export const DETAIL_TABLE_ROW_TYPE = {
  assessment: "assessment",
  candidate: "candidate",
  request: "request",
  unallocated: "unallocated",
  disconnected: "disconnected",
  connected: "connected",
};

export const DETAIL_TABLE_POSSIBLE_ASSESSMENT_TYPES = [
  DETAIL_TABLE_ROW_TYPE.assessment,
  DETAIL_TABLE_ROW_TYPE.candidate,
];

export const DETAIL_TABLE_ACTION_BUTTON_TYPES = {
  assessment: "assessment",
  candidate: "candidate",
  request: {
    connected: "connected",
    disconnected: "disconnected",
  },
};

export const TP_FLAG_VALUES = {
  myTp: "myTp",
  otherTp: "otherTp",
};

export const TP_CHANGED_VALUES = {
  yes: "yes",
  no: "no",
};
