import routes from "app/routes";

export const globalNavigationRoutes = {
  mainRoutes: [
    {
      title: "Third Party Portal",
      links: [routes.thirdPartyAssessments, routes.thirdParties],
    },
    {
      title: "Customer Portal",
      links: [
        routes.catalog,
        routes.myList,
        routes.purchased,
        routes.templates,
        routes.settings,
        routes.developerGuide,
      ],
    },
    // {
    //   title: "Registration Portal",
    //   links: [routes.myNetwork],
    // },
    {
      title: "Administrative",
      links: [routes.trusightUsers, routes.manageCustomers],
    },
    {
      title: "Book Of Work",
      links: [routes.bookOfWork],
    },
    {
      title: "Organization",
      links: [routes.myOrganization, routes.organizationPeople],
    },
    {
      title: "Admin",
      links: [routes.uploadMappingFile],
    },
  ],
  bottomNavigationRoutes: [routes.sitemap, routes.contactUs],
};

export const canAccess = (permissionRequired, permissionsHave) => {
  return permissionRequired
    ? permissionsHave &&
        (permissionsHave.includes(permissionRequired) ||
          (Array.isArray(permissionRequired) &&
            permissionsHave.some((p) => permissionRequired.includes(p))))
    : true;
};
