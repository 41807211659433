import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InputError from "components/GlobalComponents/Inputs/InputError";
import "./input.scss";

const Input = ({
  className,
  dataTestId,
  id,
  value,
  label,
  isDisabled,
  onChange,
  onBlur,
  onFocus,
  inputType,
  name,
  errorText,
  explanation,
  isRequired,
  readOnly,
  isDark,
  isAutoFocus,
  maxLength,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onInputChange = (event) => {
    setInputValue(event.target.value);
    // Do refactor for flyout
    if (inputType === "number") {
      onChange({ key: id, value: parseInt(event.target.value) });
    } else {
      onChange({ key: id, value: event.target.value });
    }
  };

  const onFocusInternal = (event) => {
    if (!readOnly) {
      setIsActive(true);
    }
    onFocus(event);
  };

  const onBlurInternal = (event) => {
    if (!readOnly) {
      setIsActive(false);
    }
    setInputValue(event.target.value.trim());
    onBlur(event, id);
  };

  return (
    <div
      className={classNames("input", className, {
        "input--disabled": isDisabled,
        "input--dark": isDark,
      })}
    >
      <div className="input__wrapper">
        <input
          name={name}
          className={classNames("input__input", {
            "input__input--error": errorText,
            "input__input--readonly": readOnly,
          })}
          data-testid={dataTestId}
          id={id}
          type={inputType}
          value={inputValue}
          required={isRequired}
          disabled={isDisabled}
          onFocus={onFocusInternal}
          onBlur={onBlurInternal}
          onChange={onInputChange}
          readOnly={readOnly}
          autoFocus={isAutoFocus}
          maxLength={maxLength}
        />
        <label
          className={classNames("input__label", {
            "is-active": isActive || inputValue,
            "input__label--readonly": readOnly,
            "input__label--required": isRequired,
            "input__label--has-value": inputValue,
          })}
          data-testid={`${dataTestId}-label`}
          htmlFor={id}
        >
          {label}
          {isRequired && !errorText && (
            <span className="visually-hidden">Required Field</span>
          )}
          {errorText && <span className="visually-hidden">{errorText}</span>}
        </label>
      </div>
      {!errorText && explanation && (
        <span className="input__explanation" data-testid="input-explanation">
          {explanation}
        </span>
      )}
      {errorText && (
        <InputError errorText={errorText} data-testid="input-error" />
      )}
    </div>
  );
};

Input.defaultProps = {
  className: "",
  dataTestId: "default-input",
  value: "",
  isDisabled: false,
  onChange: () => {},
  inputType: "text",
  name: "",
  onBlur: () => {},
  onFocus: () => {},
  errorText: "",
  explanation: "",
  isRequired: false,
  readOnly: false,
  isDark: false,
  isAutoFocus: false,
  maxLength: 524288,
};

Input.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  inputType: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  errorText: PropTypes.string,
  explanation: PropTypes.string,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  isDark: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default Input;
