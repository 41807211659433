import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  organizationId: null,
  data: null,
  initialFormData: null,
  loading: false,
  error: null,
  isEditing: false,
  isFormSavePending: false,
};

const slice = createSlice({
  name: "myOrganizationDetails",
  initialState,
  reducers: {
    loadOrganizationProfile: (state, { payload }) => {
      state.data = null;
      state.organizationId = payload.id;
      state.loading = true;
      state.isFormSavePending = false;
      state.isEditing = false;
    },
    loadOrganizationProfileSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadOrganizationProfileError: (state, action) => {
      state.loading = false;
      state.organizationId = null;
      state.error = action.payload;
    },
    setInitialFormData: (state, { payload }) => {
      state.initialFormData = payload;
    },
    updateOrganizationProfile: (state) => {
      state.isFormSavePending = true;
      state.initialFormData = null;
    },
    updateOrganizationProfileError: (state) => {
      state.isFormSavePending = false;
    },
    toggleIsEditingOrganization: (state) => {
      state.isEditing = !state.isEditing;
      state.initialFormData = null;
    },
  },
});

export const {
  loadOrganizationProfile,
  loadOrganizationProfileSuccess,
  loadOrganizationProfileError,
  setInitialFormData,
  updateOrganizationProfile,
  updateOrganizationProfileError,
  toggleIsEditingOrganization,
} = slice.actions;

export default slice;
