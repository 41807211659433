export const PERMISSIONS = {
  Wip: "WIP",
  Catalog: "Catalog",
  MyList: "MyList",
  Admin: "Admin",
  MyNetwork: {
    View: "MyNetwork",
    Add: "MyNetwork.Add",
    Manage: "MyNetwork.Manage",
  },
  CustomTemplate: {
    View: {
      MyOrganization: "CustomTemplate.View.MyOrganization",
    },
    Edit: {
      MyOrganization: "CustomTemplate.Edit.MyOrganization",
    },
  },
  TagsCollection: {
    View: {
      MyOrganization: "TagsCollection.View.MyOrganization",
    },
    Edit: {
      MyOrganization: "TagsCollection.Edit.MyOrganization",
    },
  },
  CanWatch: "CanWatch",
  CanPurchase: "CanPurchase",
  CanUploadCsv: "CanUploadCsv",
  ManageInternalUsers: "ManageInternalUsers",
  ManageCustomers: "ManageCustomers",
  ManageDemand: "ManageDemand",
  Organization: {
    CompanyProfile: {
      EditFull: "Organization.CompanyProfile.Edit.Full",
      EditMyOrganization: "Organization.CompanyProfile.Edit.MyOrganization",
      View: "Organization.CompanyProfile.View.Full",
    },
    People: {
      View: "Organization.People.View.Full",
      Edit: {
        Full: "Organization.People.Edit.Full",
        MyOrganization: "Organization.People.Edit.MyOrganization",
      },
      Invite: {
        Full: "Organization.People.Invite.Full",
        MyOrganization: "Organization.People.Invite.MyOrganization",
      },
    },
  },
  ThirdParty: {
    Full: "ThirdParty.Full",
    MyOrganization: "ThirdParty.MyOrganization",
    Assigned: "ThirdParty.Assigned",
    CompanyProfile: {
      EditFull: "ThirdParty.CompanyProfile.Edit.Full",
      EditMyOrganization: "ThirdParty.CompanyProfile.Edit.MyOrganization",
      EditAssigned: "ThirdParty.CompanyProfile.Edit.Assigned",
    },
    People: {
      ViewFull: "ThirdParty.People.View.Full",
      ViewAssigned: "ThirdParty.People.View.Assigned",
      ViewMyOrganization: "ThirdParty.People.View.MyOrganization",
      Edit: {
        Full: "ThirdParty.People.Edit.Full",
        MyOrganization: "ThirdParty.People.Edit.MyOrganization",
        Assigned: "ThirdParty.People.Edit.Assigned",
      },
      Add: {
        Full: "ThirdParty.People.Add.Full",
        MyOrganization: "ThirdParty.People.Add.MyOrganization",
      },
    },
    ViewAuthorizations: "ThirdParty.ViewAuthorizations",
  },
  Assessment: {
    Full: "Assessment.Full",
    Assigned: "Assessment.Assigned",
    MyOrganization: "Assessment.MyOrganizationFull",
    ViewDashboard: "AssessmentViewDashboard",
    ViewSchedule: "AssessmentViewSchedule",
    ViewDetails: "AssessmentViewDetails",
    InitializeBpq: "Assessment.InitializeBpq",
    Stage10: {
      Full: "Assessment.Stage10.Full",
      Assigned: "Assessment.Stage10.Assigned",
    },
    PeerReview: {
      View: "Assessment.PeerReview.View",
      Status: {
        Edit: "Assessment.PeerReview.Status.Edit",
        Partial: {
          Edit: "Assessment.PeerReview.Status.Partial.Edit",
        },
      },
      AssessorComment: {
        Edit: "Assessment.PeerReview.AssessorComment.Edit",
      },
    },
    Authorizations: {
      View: {
        Full: "Assessment.Authorizations.View.Full",
        Assigned: "Assessment.Authorizations.View.Assigned",
        MyOrganization: "Assessment.Authorizations.View.MyOrganization",
      },
      Edit: {
        Full: "Assessment.Authorizations.Edit.Full",
        Assigned: "Assessment.Authorizations.Edit.Assigned",
        MyOrganization: "Assessment.Authorizations.Edit.MyOrganization",
      },
    },
    Products: {
      View: "Assessment.Products.View",
      Generate: "Assessment.Products.Generate",
    },
    Bpq: {
      View: "Assessment.Bpq.View",
      ViewConditional: "Assessment.Bpq.View.Conditional",
      Submit: {
        Full: "Assessment.Bpq.Submit.Full",
        Assigned: "Assessment.Bpq.Submit.Assigned",
        MyOrganization: "Assessment.Bpq.Submit.MyOrganization",
      },
      SME: {
        Edit: "Assessment.Bpq.SME.Edit",
      },
      ExportAgenda: "Assessment.Bpq.ExportAgenda",
      ExportObservation: "Assessment.Bpq.ExportObservation",
      Export: "Assessment.Bpq.Export",
      SetAnswer: "Assessment.Bpq.SetAnswer",
      SetAnswerConditional: "Assessment.Bpq.SetAnswer.Conditional",
      Scope: {
        Full: "Assessment.Bpq.Scope.Full",
        Assigned: "Assessment.Bpq.Scope.Assigned",
      },
    },
    CommentBuilder: {
      Observation: {
        View: "Assessment.CommentBuilder.Observation.View",
        Edit: "Assessment.CommentBuilder.Observation.Edit",
      },
      InternalNotes: {
        View: "Assessment.CommentBuilder.InternalNotes.View",
        Edit: "Assessment.CommentBuilder.InternalNotes.Edit",
      },
      SharedNotes: {
        View: "Assessment.CommentBuilder.SharedNotes.View",
        Edit: "Assessment.CommentBuilder.SharedNotes.Edit",
      },
      Drawer: {
        View: "Assessment.CommentBuilder.Drawer.View",
        Edit: "Assessment.CommentBuilder.Drawer.Edit",
        GeneratedComment: {
          Edit: "Assessment.CommentBuilder.Drawer.GeneratedComment.Edit",
          View: "Assessment.CommentBuilder.Drawer.GeneratedComment.View",
        },
      },
    },
    Flags: {
      View: {
        Full: "Assessment.Flags.View.Full",
        Assigned: "Assessment.Flags.View.Assigned",
      },
      Edit: {
        Stage10_13: {
          Full: "Assessment.Flags.Edit.Stage10_13.Full",
        },
        Stage12And13: {
          Full: "Assessment.Flags.Edit.Stage12And13.Full",
        },
      },
      Partial: {
        Edit: {
          Stage10And11: {
            Full: "Assessment.Flags.Partial.Edit.Stage10And11.Full",
          },
          Stage11And13: {
            Full: "Assessment.Flags.Partial.Edit.Stage11And13.Full",
            Assigned: "Assessment.Flags.Partial.Edit.Stage11And13.Assigned",
          },
        },
      },
      StatusAndAssessorComment: {
        Edit: {
          Stage12: {
            Full: "Assessment.Flags.StatusAndAssessorComment.Edit.Stage12.Full",
            Assigned:
              "Assessment.Flags.StatusAndAssessorComment.Edit.Stage12.Assigned",
          },
        },
      },
    },
    Observation: {
      ThirdPartyResponse: {
        Edit: {
          Assessor: "Assessment.Observation.ThirdPartyResponse.Edit.Assessor",
          ThirdParty: "Assessment.Observation.ThirdPartyResponse.Edit.TP",
        },
        View: "Assessment.Observation.ThirdPartyResponse.View",
      },
    },
    Scoping: {
      Edit: "Assessment.Scoping.Edit",
      View: "Assessment.Scoping.View",

      Summary: {
        Edit: "Assessment.Scoping.Summary.Edit",
      },
      QuestionSummary: {
        Edit: "Assessment.Scoping.QuestionSummary.Edit",
      },
    },
    Artifacts: {
      Delete: {
        Full: "Assessment.Artifacts.Delete.Full",
        MyOrganization: "Assessment.Artifacts.Delete.MyOrganization",
      },
      Upload: "Assessment.Artifacts.Upload",
      View: "Assessment.Artifacts.View",
      Download: "Assessment.Artifacts.Download",
    },
    Schedule: {
      Edit: {
        Full: "Assessment.Schedule.Edit.Full",
        Assigned: "Assessment.Schedule.Edit.Assigned",
      },
    },
    AllowCustomTemplates: {
      Edit: "Assessment.AllowCustomTemplates.Edit",
    },
    ChangeStage: {
      Forward: "Assessment.ChangeStage.Forward",
      Backward: "Assessment.ChangeStage.Backward",
    },
    Finalize: {
      Full: "Assessment.Finalize.Full",
      MyOrganization: "Assessment.Finalize.MyOrganization",
    },
    People: {
      View: "Assessment.People.View",
      Edit: {
        ThirdParty: "Assessment.People.Edit.TP",
        TruSight: "Assessment.People.Edit.TS",
      },
    },
  },
  Purchased: {
    View: {
      Full: "Purchased.View.Full",
    },
  },
  SettingsAndIntegrations: {
    ApiKeys: {
      Manage: "SettingsAndIntegrations.ApiKeys.Manage",
    },
    View: "SettingsAndIntegrations.View",
  },
  DeveloperGuide: {
    View: "DeveloperGuide.View",
  },
};

export const NAVIGATION_GROUPS = {
  Assessments: {
    title: "Assessments Pages",
    requiredPermissions: [PERMISSIONS.Wip, PERMISSIONS.Catalog],
  },
  MyList: {
    title: "Third Parties",
    requiredPermissions: [PERMISSIONS.MyList],
  },
  Purchased: {
    title: "Purchased",
    requiredPermissions: [PERMISSIONS.Purchased.View.Full],
  },
  MyNetwork: {
    title: "My Network",
    requiredPermissions: [PERMISSIONS.MyNetwork.View],
  },
  TemplatesAndMapping: {
    title: "Templates & Tags",
    requiredPermissions: [
      PERMISSIONS.CustomTemplate.View.MyOrganization,
      PERMISSIONS.TagsCollection.View.MyOrganization,
    ],
    disabled: process.env.REACT_APP_FEATURE_CUSTOM_TEMPLATES !== "true",
  },
  SettingsAndIntegrations: {
    title: "Settings & Integrations",
    requiredPermissions: [
      PERMISSIONS.SettingsAndIntegrations.View,
      PERMISSIONS.DeveloperGuide.View,
    ],
    disabled: process.env.REACT_APP_FEATURE_CONSUMPTION_API !== "true",
  },
  ThirdParties: {
    title: "Third Parties",
    requiredPermissions: [
      PERMISSIONS.ThirdParty.Full,
      PERMISSIONS.ThirdParty.MyOrganization,
      PERMISSIONS.ThirdParty.Assigned,
    ],
    disabled: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP === "true",
  },
  About: {
    title: "About TruSight",
    // no permissions are required to see contact us page hence *
    requiredPermissions: ["*"],
  },
  Administrative: {
    title: "Administrative",
    requiredPermissions: [
      PERMISSIONS.ManageInternalUsers,
      PERMISSIONS.ManageCustomers,
    ],
    disabled: process.env.REACT_APP_FEATURE_SUPPRESS_TPAP === "true",
  },
};

export const DEFAULT_TITLE = "TruSight Customer Portal";

export const PERMISSIONS_TO_OPEN_PEER_REVIEW_PANEL = [
  PERMISSIONS.Assessment.PeerReview.AssessorComment.Edit,
  PERMISSIONS.Assessment.PeerReview.Status.Edit,
  PERMISSIONS.Assessment.PeerReview.Status.Partial.Edit,
  PERMISSIONS.Assessment.PeerReview.View,
];
export const PERMISSIONS_TO_OPEN_QC_FLAG_PANEL = [
  PERMISSIONS.Assessment.Flags.View.Assigned,
  PERMISSIONS.Assessment.Flags.View.Full,
];

export const PERMISSIONS_TO_SEE_QC_FLAG_COLUMN_FROM_STAGE_11 =
  PERMISSIONS_TO_OPEN_QC_FLAG_PANEL.concat(
    PERMISSIONS.Assessment.Flags.Partial.Edit.Stage11And13.Full,
    PERMISSIONS.Assessment.Flags.Partial.Edit.Stage11And13.Assigned,
    PERMISSIONS.Assessment.Flags.StatusAndAssessorComment.Edit.Stage12.Full,
    PERMISSIONS.Assessment.Flags.StatusAndAssessorComment.Edit.Stage12.Assigned
  );
