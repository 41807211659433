import { useSelector } from "react-redux";

import { selectRolesAvailableForSelect } from "pages/OrganizationPeoplePage/services/selectors";

export const useUserRoleIds = (userRoles, availableRolesToGrant) => {
  const availableRoles = useSelector(selectRolesAvailableForSelect);

  let roleIds = [];
  if (availableRoles && userRoles) {
    roleIds = userRoles.reduce((acc, userRole) => {
      const role = availableRoles.find((role) => role.name === userRole);
      if (role) {
        acc.push(role.id);
      }
      return acc;
    }, []);
  }
  return roleIds;
};

export const canEditUser = (userRoles, availableRolesToGrant) => {
  const hasMatchingRole = userRoles.some((userRole) =>
    availableRolesToGrant.some((role) => role.name === userRole)
  );

  return hasMatchingRole;
};
