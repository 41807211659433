import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "user",
  initialState: {
    information: null,
    token: null,
    loading: false,
    error: null,
    isPasswordModalOpen: false,
    modalContent: {
      isLoading: false,
      isClosingModal: false,
      validationError: "",
    },
  },
  reducers: {
    loadUserInfo: (state) => {
      state.information = null;
      state.loading = true;
      state.error = null;
    },
    loadUserInfoError: (state, { payload }) => {
      state.information = null;
      state.loading = false;
      state.error = payload;
    },
    loadUserInfoSuccess: (state, { payload }) => {
      state.information = payload;
      state.loading = false;
      state.error = null;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsPasswordModalOpen: (state, { payload }) => {
      state.isPasswordModalOpen = payload;
    },
    updatePassword: (state) => {
      state.modalContent.isLoading = true;
    },
    updatePasswordSuccess: (state, action) => {
      state.isPasswordModalOpen = false;
      state.modalContent.isLoading = false;
    },
    updatePasswordError: (state, action) => {
      state.modalContent.validationError = action.payload.title;
    },
  },
});

export const {
  setToken,
  loadUserInfo,
  loadUserInfoError,
  loadUserInfoSuccess,
  setIsPasswordModalOpen,
  updatePassword,
  updatePasswordError,
  updatePasswordSuccess,
} = slice.actions;

export default slice;
