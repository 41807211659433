import React, { useEffect, useState, useRef, memo } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import { ReactComponent as TruSightLogo } from "assets/TruSight-SPG_hor_white_rgb.svg";
import { ReactComponent as ChevronIcon } from "assets/chevron.svg";
import Button from "components/GlobalComponents/Buttons/Button";
import { ReactComponent as QuestionMarkIcon } from "assets/question-mark.svg";
import DropdownMenu from "components/DropdownMenu";
import Hamburger from "./components/Hamburger";
import useCheckOutsideClick from "utils/useCheckOutsideClick/useCheckOutsideClick";
import {
  selectSingleSignOnEnabled,
  selectUserInfo,
} from "./services/selectors";
import {
  setToken,
  loadUserInfo,
  setIsPasswordModalOpen,
} from "./services/slice";
import { selectSlice } from "./services/selectors";
import Notifications from "components/Notifications/Notifications";
import { buttonTypes } from "components/GlobalComponents/Buttons/Button/Button";
import ButtonIcon from "components/GlobalComponents/Buttons/ButtonIcon";
import useAccessToken from "utils/useAccessToken/useAccessToken";
import routes from "app/routes";
import { TOOLTIP_POSITIONS } from "app/constants";
import messages from "app/messages";
import { logoutSaga } from "../../services/logout/sagas";
import "./header.scss";
import ChangePasswordModal from "./components/ChangePasswordModal";

function Header() {
  const dropdownWrapper = useRef();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { oktaAuth } = useOktaAuth();
  const accessToken = useAccessToken();
  const { isPasswordModalOpen } = useSelector(selectSlice);
  const singleSignOn = useSelector(selectSingleSignOnEnabled);

  const intl = useIntl();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);

  const handleLogoutButtonClick = () => {
    logoutSaga({ accessToken });
    oktaAuth.signOut();
  };

  useEffect(() => {
    if (accessToken && !userInfo) {
      dispatch(loadUserInfo({ accessToken }));
    }
  }, [accessToken, dispatch, userInfo]);

  useEffect(() => {
    // Access token expired, logging out
    if (!accessToken) {
      logoutSaga({ accessToken });
      oktaAuth.signOut();
    }
    dispatch(setToken(accessToken));
  }, [oktaAuth, accessToken, dispatch]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useCheckOutsideClick(dropdownWrapper, toggleDropdown, isDropdownOpen);

  const openPasswordModal = () => {
    dispatch(setIsPasswordModalOpen(true));
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closePasswordModal = () => {
    dispatch(setIsPasswordModalOpen(false));
  };

  return (
    <header className="header">
      <div className="header__frame">
        <div className="header__content">
          <Hamburger />
          <Link to={routes.home.path}>
            <TruSightLogo className="header__logo" />
          </Link>
          {userInfo && (
            <div
              data-testid="header-actions"
              className="header__actions-wrapper"
            >
              <div className="header__dropdown-wrapper" ref={dropdownWrapper}>
                <Button
                  data-testid="toggle-button"
                  ariaExpanded={isDropdownOpen}
                  ariaHasPopup
                  followingIcon={<ChevronIcon />}
                  isSecondary
                  onClick={toggleDropdown}
                  buttonType={buttonTypes.link}
                >
                  {userInfo.fullName}
                </Button>
                <DropdownMenu isDropdownOpen={isDropdownOpen}>
                  <div className="header__dropdown-content">
                    <p className="header__dropdown-subheading">
                      {intl.formatMessage(messages.organization)}
                    </p>
                    <h4 className="header__dropdown-heading">
                      {userInfo.organizationName}
                    </h4>
                    <p className="header__dropdown-item">
                      <span className="header__dropdown-item-name">
                        {intl.formatMessage(messages.role)}:
                      </span>
                      {userInfo.roles?.join(", ")}
                    </p>
                    <p className="header__dropdown-item">
                      <span className="header__dropdown-item-name">
                        {intl.formatMessage(messages.organizationId)}:
                      </span>
                      {userInfo.organizationId}
                    </p>
                    <Button
                      className="header__dropdown-button"
                      data-testid="logout-button"
                      onClick={handleLogoutButtonClick}
                      type="button"
                      isLight
                    >
                      {intl.formatMessage(messages.logout)}
                    </Button>
                    {!singleSignOn && (
                      <Button
                        className="header__dropdown-button"
                        data-testid="change-password-button"
                        onClick={openPasswordModal}
                        type="button"
                        isLight
                      >
                        {intl.formatMessage(messages.changePassword)}
                      </Button>
                    )}
                  </div>
                </DropdownMenu>
              </div>
              <div className="header__icons-wrapper">
                <ButtonIcon
                  className="header__icon"
                  isAnchor
                  href={process.env.REACT_APP_CUSTOMER_SUPPORT_URL}
                  icon={<QuestionMarkIcon />}
                  tooltipPosition={TOOLTIP_POSITIONS.bottomCenter}
                  tooltipText="Help"
                />
                <Notifications />
              </div>
            </div>
          )}
        </div>
      </div>
      {isPasswordModalOpen && (
        <div className="header__password-wrapper">
          <ChangePasswordModal setModalOpen={closePasswordModal} />
        </div>
      )}
    </header>
  );
}

export default memo(Header);
