import { createSlice } from "@reduxjs/toolkit";

import { canEditUser } from "../components/EditUserForm/utils/utils";

export const initialState = {
  organizationId: null,
  data: null,
  isLoading: false,
  error: null,
  userInEditing: null,
  isAddModalOpen: false,
  modalContent: {
    organizationRoles: null,
    wizardStep: 0,
    isLoading: false,
    isClosingModal: false,
    validationError: "",
    validatedContact: "",
    initialStoredValues: null,
    isFormSubmitting: false,
    formSubmitErrors: {},
    isFormSubmitSuccess: false,
  },
  availableOrganizationRoles: [],
};

const slice = createSlice({
  name: "organizationPeople",
  initialState,
  reducers: {
    loadOrganizationPeople: (state, { payload }) => {
      state.data = null;
      state.organizationId = payload.id;
      state.isLoading = true;
    },
    loadOrganizationPeopleSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    updateWithCanIEdit: (state) => {
      const { availableOrganizationRoles } = state;
      state.data = state.data.map((person) => {
        return {
          ...person,
          canEdit: canEditUser(person.roles, availableOrganizationRoles),
        };
      });
    },
    loadOrganizationPeopleError: (state, action) => {
      state.organizationId = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    loadRoles: () => {},
    loadRolesSuccess: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        organizationRoles: action.payload,
      };

      state.availableOrganizationRoles = action.payload;
    },
    loadRolesError: (state, action) => {
      state.error = action.payload;
      state.modalContent = {
        ...state.modalContent,
        organizationRoles: [],
      };
    },
    addUser: (state) => {
      state.isLoading = true;
      state.modalContent.isClosingModal = true;
    },
    addOrganizationUserSuccess: (state, action) => {
      state.isLoading = false;
    },
    addOrganizationUserError: (state) => {
      state.isLoading = false;
    },
    updateOrganizationUser: (state, action) => {
      state.isLoading = true;
      state.modalContent.isClosingModal = true;
    },
    updateOrganizationUserSuccess: (state, action) => {
      state.isLoading = false;
    },
    updateOrganizationUserError: (state, action) => {
      state.isLoading = false;
    },
    validateUserEmail: (state) => {
      state.modalContent.isLoading = true;
    },
    validateUserEmailSuccess: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        validationError: "",
        validatedContact: "",
        selectedContact: action.payload.email,
        wizardStep: 1,
        isLoading: false,
      };
    },
    validateUserEmailError: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        validationError: action.payload.errors.Email[0],
        validatedContact: action.payload.email,
        isLoading: false,
      };
    },
    setWizardStep: (state, action) => {
      state.modalContent = {
        ...state.modalContent,
        wizardStep: action.payload,
      };
    },
    clearModalContent: (state) => {
      state.userInEditing = null;
      state.modalContent = {
        organizationRoles: null,
        organizationContacts: null,
        selectedContact: null,
        wizardStep: 0,
        isLoading: false,
        validationError: "",
        isClosingModal: false,
        initialStoredValues: null,
      };
    },
    setIsAddModalOpen: (state, { payload }) => {
      state.isAddModalOpen = payload;
    },
    setInitialModalValues: (state, { payload }) => {
      state.modalContent = {
        ...state.modalContent,
        initialStoredValues: payload,
      };
    },
    setUserInEditing: (state, { payload }) => {
      state.userInEditing = payload;
    },
    setFormSubmitErrors: (state, action) => {
      state.modalContent.formSubmitErrors = action.payload;
    },
    setIsFormSubmitting: (state, action) => {
      state.modalContent.isFormSubmitting = action.payload;
    },
    setIsFormSubmitSuccess: (state, action) => {
      state.modalContent.isFormSubmitSuccess = action.payload;
    },
  },
});

export const {
  loadOrganizationPeople,
  loadOrganizationPeopleSuccess,
  loadOrganizationPeopleError,
  setIsAddModalOpen,
  clearModalContent,
  setWizardStep,
  setInitialModalValues,
  loadRoles,
  loadRolesSuccess,
  loadRolesError,
  addUser,
  addOrganizationUserSuccess,
  addOrganizationUserError,
  validateUserEmail,
  validateUserEmailSuccess,
  validateUserEmailError,
  setUserInEditing,
  setFormSubmitErrors,
  setIsFormSubmitting,
  setIsFormSubmitSuccess,
  updateWithCanIEdit,
  updateOrganizationUser,
  updateOrganizationUserSuccess,
  updateOrganizationUserError,
} = slice.actions;

export default slice;
