import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import FocusLock from "react-focus-lock";
import { useIntl } from "react-intl";

import Button from "components/GlobalComponents/Buttons/Button";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { selectSlice } from "services/drawer/selectors";
import {
  selectIsCustomCommentDirty,
  selectIsDirty,
} from "services/form/selectors";
import { KEYS } from "app/keys";
import { handleDirtyFormPrompt } from "utils/handleDirtyFormPrompt/handleDirtyFormPrompt";
import "./drawer.scss";

const Drawer = ({
  children,
  className,
  footerControls,
  headerControls,
  setDrawerOpen,
  hideCloseButton,
  drawerOpen,
  focusLockDisabled,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isDirty = useSelector(selectIsDirty);
  const isCustomCommentDirty = useSelector(selectIsCustomCommentDirty);
  const { fieldState } = useSelector(selectSlice);

  const memoizedCloseDrawer = useCallback(() => {
    const handleDrawerClose = () => setDrawerOpen(false);
    handleDirtyFormPrompt(
      handleDrawerClose,
      isDirty || isCustomCommentDirty,
      intl,
      dispatch
    );
  }, [setDrawerOpen, intl, isDirty, dispatch, isCustomCommentDirty]);

  useEffect(() => {
    const closeDrawerOnKeyDown = (e) => {
      if (e.code === KEYS.escape.code || e.keyCode === KEYS.escape.keyCode) {
        memoizedCloseDrawer();
      }
    };

    if (drawerOpen && !fieldState.isOpen) {
      window.addEventListener("keydown", closeDrawerOnKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", closeDrawerOnKeyDown);
    };
  }, [drawerOpen, memoizedCloseDrawer, fieldState]);

  return (
    <FocusLock disabled={focusLockDisabled || !drawerOpen}>
      <div
        data-testid="drawer"
        className={classNames("drawer", className, {
          "drawer--hidden": !drawerOpen,
        })}
        role="dialog"
        aria-label="assessment details"
      >
        <div className="drawer__wrapper">
          {(!hideCloseButton || headerControls) && (
            <div data-testid="drawer-wrapper" className="drawer__header">
              {!hideCloseButton && (
                <Button
                  data-testid="drawer-close-button"
                  className="drawer__header-btn drawer__header-btn--small-icon drawer__header-btn--close"
                  isSmall
                  leadingIcon={<CloseIcon />}
                  onClick={memoizedCloseDrawer}
                >
                  Close
                </Button>
              )}
              {headerControls}
            </div>
          )}
          <div
            className={classNames("drawer__content", {
              "drawer__content--no-footer": !footerControls,
            })}
            data-testid="drawer-content"
          >
            {children}
          </div>
          {footerControls && (
            <div className="drawer__footer" data-testid="drawer-footer">
              {footerControls}
            </div>
          )}
        </div>
      </div>
    </FocusLock>
  );
};

export default Drawer;

Drawer.defaultProps = {
  children: null,
  className: "",
  footerControls: null,
  headerControls: null,
  hideCloseButton: false,
  focusLockDisabled: false,
};

Drawer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  footerControls: PropTypes.node,
  headerControls: PropTypes.node,
  setDrawerOpen: PropTypes.func.isRequired,
  hideCloseButton: PropTypes.bool,
  drawerOpen: PropTypes.bool.isRequired,
  focusLockDisabled: PropTypes.bool,
};
